<template>
  <div v-if="!showLoading" class="container" style="min-height: auto;">
    <Progress :quizIndex="quizIndex" :maxQuizLength="quiz.length" />
    <transition-group name="slide" tag="div" class="div-slider" v-for="(q, i) in quiz" :key="i">
      <header
        v-if="i === quizIndex && quizTransition"
        :key="`header-${i}`"
        class="quiz-title"
        v-html="q.q"
      ></header>
      <div v-if="i === quizIndex && quizTransition" class="cover" :key="`cover-${i}`">
        <button
          v-for="(a, i) in q.a"
          class="quiz"
          :key="`answer-${i}`"
          v-html="a.txt"
          @click="getScore(a.type)"
        ></button>
      </div>
    </transition-group>
  </div>
  <div v-else class="container loading">
    <img class="loading-img" :src="`/image/프링글스.png`" />
    <div class="loading-text">곧 결과가 나옵니다{{loadingText}}</div>
  </div>
</template>
<script>
import Progress from "@/components/Progress";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "Test",
  components: {
    Progress
  },
  data() {
    return {
      quizIndex: 0,
      quizTransition: true,
      showLoading: false,
      loadingText: "",
      result: {
        e: 0,
        i: 0,
        s: 0,
        n: 0,
        t: 0,
        f: 0,
        j: 0,
        p: 0
      },
      quiz: [
        {
          q: "잼 뚜껑을 따려는데<br/>잘 안 따진다…",
          a: [
            {
              txt: "친구에게 부탁한다.",
              type: "e"
            },
            {
              txt: "우선 혼자 끙끙댄다.",
              type: "i"
            }
          ]
        },
        {
          q: "멘토스와 탄산음료의<br/>폭발실험을 본 당신",
          a: [
            {
              txt: "저걸 언제 치워…",
              type: "s"
            },
            {
              txt: "오 재미있어 보이는데? ",
              type: "n"
            }
          ]
        },
        {
          q:
            "애인이 통조림 황도를 먹다가<br/>\"네가 생각나서 전화했어.\"라고<br/>한다면 당신은?",
          a: [
            {
              txt: "아 진짜? 내 생각이 났어? ㅎㅎ",
              type: "f"
            },
            {
              txt: "그걸 먹다가 왜 내 생각이 나?",
              type: "t"
            }
          ]
        },
        {
          q: "점심거리를 사러<br/>마트로 향하는 당신,",
          a: [
            {
              txt: "미리 생각해놓고<br/>사기로 한 것만 산다.",
              type: "j"
            },
            {
              txt: "대충 생각하고 마트에 가서<br/>feel대로 산다.",
              type: "p"
            }
          ]
        },
        {
          q:
            "아침은 콘푸로스트 vs 밥이라는<br/>두 친구의 논쟁을 지켜보며<br/>침묵하는 당신, 그 이유는?",
          a: [
            {
              txt: "어느 의견에 동의하거나<br/>아예 관심이 없어서",
              type: "e"
            },
            {
              txt: "그 의견을 듣고 내 생각이나<br/>감정을 정리하고 하고 있어서",
              type: "i"
            }
          ]
        },
        {
          q: "간편식을 만드는데<br/>조리법을 잘 모르는 당신",
          a: [
            {
              txt: "그냥 레시피대로 만든다.",
              type: "s"
            },
            {
              txt: "레시피는 쓱 보고 감대로 한다.",
              type: "n"
            }
          ]
        },
        {
          q:
            '"내 최애 과자를<br/>친구에게 소개했는데<br/>‘난 그거 별로’라는 말을 들었을 때',
          a: [
            {
              txt: "내 인생 과자를..! ㅂㄷㅂㄷ",
              type: "f"
            },
            {
              txt: "뭐 자기 생각이니까<br/>어쩔 수 없지 ㅇㅈㅇㅈ",
              type: "t"
            }
          ]
        },
        {
          q:
            "간편식을 조리하다가 추가 식재료를<br/>넣어 열심히 요리한 당신,<br/>먹어보니 맛이 그닥이다.<br/>이때 당신은?",
          a: [
            {
              txt: "아… 맛 없어. 화가 나--;;",
              type: "j"
            },
            {
              txt:
                "그래도 열심히 했으니까 뭐 ㅎ<br/>먹다 보니까 맛있는 거 같기도 ㅎㅎ",
              type: "p"
            }
          ]
        },
        {
          q: "당신이 친목 모임을 만든다면<br/>이 모임의 이름은?",
          a: [
            {
              txt:
                "다양한 개성이 물씬 느껴지는<br/>에너제틱한 이들의 모임, 츄파춥스.",
              type: "e"
            },
            {
              txt:
                "소수의 인원으로 구성, 우리만의 진한<br/>이야기를 쌓아가는 모임, 엑설런스.",
              type: "i"
            }
          ]
        },
        {
          q:
            `친구에게 "만약에 통조림 햄이<br/>0kcal 너는 어떻게 할 거야?"<br/>라는 질문을 받았다.<br/>이때 당신은?`,
          a: [
            {
              txt: "뭔 소리야, 그럴 리가 없잖아.",
              type: "s"
            },
            {
              txt: "오? 그럼 개꿀이지~",
              type: "n"
            }
          ]
        },
        {
          q: "앞에 사람이 생수 12병을<br/>힘들게 들고 가는 걸 본 당신",
          a: [
            {
              txt: "많이 힘들겠다…",
              type: "f"
            },
            {
              txt: "편하게 배달시키지…",
              type: "t"
            }
          ]
        },
        {
          q: "친구들이 내일 우리 집에서<br/>점심을 먹는다고 한다. 이때 당신은?",
          a: [
            {
              txt: "내일 점심으로 먹을 음식을<br/>곰곰이 생각한다.",
              type: "j"
            },
            {
              txt: "아 그래? 그래!",
              type: "p"
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.quizIndex = 0;
    this.result = {
      e: 0,
      i: 0,
      s: 0,
      n: 0,
      t: 0,
      f: 0,
      j: 0,
      p: 0
    };
  },
  methods: {
    getScore(type) {
      this.result[type] += 1;
      this.quizIndex += 1;
      this.quizTransition = false;
      setTimeout(() => {
        this.quizTransition = true;
      }, 210);
      if (this.quizIndex >= this.quiz.length) {
        const result = this.getResult();
        const increment = firebase.firestore.FieldValue.increment(1);
        const docRef = this.$firestore.collection("user").doc("fbti");
        docRef.update({ finishCount: increment });
        docRef.update({ [result]: increment });
        this.showLoading = true;
        setInterval(() => {
          this.loadingText += ".";
        }, 700);
        setTimeout(() => {
          this.$router.push({
            name: "Result",
            params: {
              id: result
            }
          });
        }, 3000);
      }
    },
    getResult() {
      const result = [
        this.result.e > this.result.i ? "e" : "i",
        this.result.s > this.result.n ? "s" : "n",
        this.result.f > this.result.t ? "f" : "t",
        this.result.j > this.result.p ? "j" : "p"
      ];
      return result.join("");
    }
  }
};
</script>