<template>
  <div class="progress-container">
    <h1 class="main-top">Food Brand MBTI Test</h1>
    <div class="progress-bar">
      <span class="current-number">{{quizIndex + 1}}/{{maxQuizLength}}</span>
      <div class="fill" :style="{width: `${(quizIndex + 1)/maxQuizLength * 100}%`}"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    quizIndex: {
      type: Number,
      default() {
        return 0;
      }
    },
    maxQuizLength: {
      type: Number,
      default() {
        return 12;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.progress-container {
  margin-bottom: 40px;
  .progress-bar {
    width: 345px;
    background: #f2f2f2;
    height: 5px;
    display: flex;
    .current-number {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -30px);
      font-size: 12px;
      line-height: 25px;
      color: #ff9999;
    }
    .fill {
      background-color: #ff9999;
    }
  }
}
</style>